.wrapper {
  width : 700px;
  margin: auto;
  color : #404040;
}

.header {
  margin-bottom: 35px;

  a {
    margin-left: 16px;
  }
}

h1 {
  margin   : 40px 0 14px 0;
  font-size: 28px;
}

.createInfo {
  display    : flex;
  align-items: center;

  span {
    margin-right: 15px;
    font-size   : 16px;
    color       : #8F8E94;
  }
}

.createTime {
  color: #404040 !important;
}

.creatorAvatar {
  width        : 33px;
  height       : 33px;
  border-radius: 17px;
  overflow     : hidden;
  margin-right : 20px;

  img {
    width: 100%;
  }
}