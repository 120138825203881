@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";

body {
  background: #f5f5f5;
  margin    : 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bp3-button.bp3-intent-primary:not(.bp3-minimal) {
  background : #3DA8F5 !important;
  height     : 36px;
  line-height: 36px;
  border     : none;
  box-shadow : none;
  padding    : 0 16px;
}

.bp3-button.bp3-minimal.bp3-intent-primary {
  color: #3DA8F5;
}

.bp3-button.bp3-intent-primary:not(.bp3-minimal):hover {
  background: #3499e1 !important;
}

.bp3-toast.bp3-intent-warning {
  background-color: #ffefe6;
  border          : solid 1px #ffa985;
  box-shadow      : none;
  color           : #404040;
}

.bp3-toast.bp3-intent-warning .bp3-icon,
.bp3-toast.bp3-intent-warning svg {
  color: #404040 !important;
}

.bp3-datepicker .DayPicker-Day.DayPicker-Day--selected {
  background-color: #3199DF;
}

.bp3-menu-item.bp3-intent-primary:hover,
.bp3-submenu .bp3-popover-target.bp3-popover-open>.bp3-intent-primary.bp3-menu-item,
.bp3-menu-item.bp3-intent-primary.bp3-active {
  /* background-color: #137cbd !important; */
  color: #404040;
}

.bp3-menu-item:hover {
  background: #eee;
}